import { V2_ACTIONS } from '../../actions/actionTypes'

const INITIAL_STATE = {
    code: '',
    valid: false,
    applied: false,
    errorMessage: null
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case V2_ACTIONS.SET_PROMO_CODE:
            return {
                ...state,
                code: action.payload.code,
                valid: action.payload.valid,
                applied: action.payload.applied,
                errorMessage: action.payload.errorMessage,
            }
        case V2_ACTIONS.SET_PROMO_CODE_INVALID:
            return {
                ...state,
                valid: false,
            }
        default:
    }
    return state
}
